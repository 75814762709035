import React, { useState, useContext, useEffect } from "react"
import { FirebaseContext } from "../components/Firebase"

const AddAuthor = () => {
  const { firebase } = useContext(FirebaseContext)
  const [authorName, setAuthorName] = useState("")
  const [succes, setSuccess] = useState(false)
  let isMounted = true

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])
  function handleSubmit(e) {
    e.preventDefault()
    firebase
      .createAuthor({
        authorName,
      })
      .then(() => {
        if (isMounted) {
          setAuthorName("")
          setSuccess(true)
        }
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        onChange={e => {
          e.persist()
          setSuccess(false)
          setAuthorName(e.target.value)
        }}
        placeholder="author name"
      />
      {succes && <span>Author has been created successfully</span>}
      <button type="submit">Add new author</button>
    </form>
  )
}

export default AddAuthor
